exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-politicas-js": () => import("./../../../src/pages/nosotros/politicas.js" /* webpackChunkName: "component---src-pages-nosotros-politicas-js" */),
  "component---src-pages-nosotros-pqrf-js": () => import("./../../../src/pages/nosotros/pqrf.js" /* webpackChunkName: "component---src-pages-nosotros-pqrf-js" */),
  "component---src-pages-nosotros-quienes-somos-js": () => import("./../../../src/pages/nosotros/quienesSomos.js" /* webpackChunkName: "component---src-pages-nosotros-quienes-somos-js" */),
  "component---src-pages-soluciones-transporte-aereo-js": () => import("./../../../src/pages/soluciones/transporteAereo.js" /* webpackChunkName: "component---src-pages-soluciones-transporte-aereo-js" */),
  "component---src-pages-soluciones-transporte-maritimo-js": () => import("./../../../src/pages/soluciones/transporteMaritimo.js" /* webpackChunkName: "component---src-pages-soluciones-transporte-maritimo-js" */),
  "component---src-pages-soluciones-transporte-terrestre-js": () => import("./../../../src/pages/soluciones/transporteTerrestre.js" /* webpackChunkName: "component---src-pages-soluciones-transporte-terrestre-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

